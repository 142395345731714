.js-site-menu-open {
  @apply overflow-hidden;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-width) + (2 * var(--container-padding)));
}

.container-sm {
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  max-width: calc(var(--container-sm-width) + (2 * var(--container-padding)));
}

.container-fluid {
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

[data-whatinput='mouse'] [role='button'],
[data-whatinput='mouse'] input[type='submit'],
[data-whatinput='mouse'] button {
  outline: none;
}

.skip-link {
  @apply absolute overflow-hidden text-sm;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);

  &:focus {
    @apply h-auto w-auto bg-white px-3 py-2 text-black;
    clip: auto;
    z-index: 10000;
  }
}

._preload * {
  transition: none !important;
}

[data-page-content].is-loading:not(.ignore-loading) {
  @apply relative blur-sm;
}

.site-wrapper {
  padding-top: var(--site-announcement-height);
}
