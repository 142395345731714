/* Clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: none;
  -webkit-appearance: none;
}

input[type='search'] {
  @apply appearance-none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@supports (-webkit-overflow-scrolling: touch) {
  input[type='search'] {
    @apply rounded-none;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -webkit-border-radius: 0px;
}
