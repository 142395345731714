.swiper-slide.is-equal-height {
  @apply md:h-auto;
}

.swiper-slide.md-half {
  @apply md:w-1/2;
}

.swiper-slide.lg-third {
  @apply lg:w-1/3;
}

.swiper-button-disabled {
  @apply pointer-events-none opacity-30;
}

.swiper.swiper-overflow-visible {
  @apply overflow-visible;
}

.swiper-pagination-bullets {
  @apply flex justify-center space-x-3;
}

.swiper-pagination-bullet {
  @apply block h-3 w-3 cursor-pointer bg-gray-light hover:bg-green;
}

.swiper-pagination-bullet-active {
  @apply bg-green;
}

.swiper.fluid-right,
.splide.fluid-right {
  @apply md:pull-right;
}

.swiper:not(.ignore-default-styles):not(.swiper-overflow-visible):not(.swiper-initialized) {
  @apply overflow-hidden;

  .swiper-wrapper {
    @apply flex;
  }
}

.autoplay-swiper.swiper-container {
    height: calc(42vh);
}

.swiper-container .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

@screen md {
  .splide__slide {
    min-height: 30rem;
  }
}
