@layer base {
  address {
    @apply not-italic;
  }

  strong {
    @apply font-medium;
  }

  sup:not([id]) {
    font-size: 0.4em;
    top: -1.2em;
    left: 0.2em;
  }

  svg.site-icon mask {
    fill: #fff;
  }
}

@layer components {
  .text-primary {
    @apply font-medium text-blue;
  }

  .text-primary-hover {
    @apply font-medium text-blue transition-colors hover:text-green;
  }

  .text-secondary {
    @apply font-medium text-green;
  }

  .text-secondary-hover {
    @apply font-medium text-green transition-colors hover:text-blue;
  }

  .h1 {
    @apply text-3xl md:text-4xl lg:text-5xl xl:text-6xl;
  }

  .h1-slow {
    @apply text-3xl md:text-4xl xl:text-5xl 2xl:text-6xl;
  }

  .h2 {
    @apply text-3xl lg:text-4xl xl:text-5xl;
  }

  .h3 {
    @apply text-2xl md:text-3xl lg:text-4xl;
  }

  .h3-slow {
    @apply text-2xl md:text-3xl xl:text-4xl;
  }

  .h4 {
    @apply text-xl md:text-2xl lg:text-3xl;
  }

  .h5 {
    @apply text-base lg:text-lg xl:text-xl;
  }

  .ft-icon {
    @apply inline-flex;

    &:before {
      @apply mr-[10px] block flex-shrink-0 bg-contain bg-no-repeat;
      content: '';
      height: 1.25em;
      width: 1.25em;
    }
  }

  .ft-pdf:before {
    background-image: url('@images/ft-pdf.svg');
  }

  .richtext {
    p {
      @apply my-4;
    }

    &.no-first-margin p {
      margin-top: 0;
    }

    &.no-margin p {
      margin: 0;
    }

    &.no-last-margin p:last-child {
      margin-bottom: 0;
    }
  }

  .prose {
    p {
      @apply my-6;
    }

    &.no-first-margin p {
      margin-top: 0;
    }

    &.no-margin p {
      margin: 0;
    }

    &.no-last-margin p:last-child {
      margin-bottom: 0;
    }

    ul:not([class]) {
      @apply my-6;
    }
  }

  .wysiwyg {
    @apply overflow-hidden;

    h1,
    h2 {
      @apply text-primary h3;
    }

    h3 {
      @apply text-primary h4;
    }

    h4,
    h5,
    h6 {
      @apply text-primary text-base lg:text-xl xl:text-2xl;
    }

    h1 + h2,
    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6 {
      @apply mt-6 lg:mt-8;
    }

    a {
      @apply text-green underline transition-colors hover:text-blue;
    }

    hr {
      @apply my-8;
    }

    ul:not([class]) {
      @apply my-4 ml-3;

      li {
        @apply relative my-2;
        padding-left: 1em;

        &:before {
          @apply absolute top-0 left-0 font-medium;
          color: theme('colors.blue.DEFAULT');
          content: '\2022';
          font-size: 1em;
          line-height: 1.6em;
        }
      }
    }

    ol:not([class]) {
      @apply my-6 space-y-4;

      & > li {
        @apply relative pl-7;
        counter-increment: section;

        &:before {
          @apply absolute left-0 mr-3 h-5 w-5 flex-shrink-0;
          content: counter(section) '.';
        }
      }
    }

    .as-button-text-secondary {
      @apply inline-flex items-center justify-between font-medium text-green no-underline transition-colors hover:text-blue xl:text-base;

      &:after {
        @apply ml-2 h-[14px] w-[14px] flex-shrink-0 bg-no-repeat;
        content: '';
        background-image: url(@images/chevron-green-right.svg);
      }

      &:hover:after {
        filter: hue-rotate(60deg);
      }
    }

    table {
      @apply min-w-full divide-y border;

      p {
        @apply m-0;
      }
    }

    thead {
      @apply bg-gray-lightest;
    }

    th {
      @apply p-3 text-left text-base font-medium;
    }

    tbody {
      @apply divide-y bg-white;

      td {
        @apply p-3 text-base;
      }

      & tr:nth-child(2n) {
        @apply bg-gray-lightest;
      }
    }
  }

  .wysiwyg-light {
    a {
      @apply underline transition-opacity hover:opacity-70;
    }
  }

  .wysiwyg-centered-list {
    ul:not([class]) {
      @apply inline-block text-left;
    }
  }

  .wysiwyg-triangle-list {
    ul {
      li {
        @apply relative pl-4;

        &:before {
          @apply absolute left-0 top-2 h-2 w-2 flex-shrink-0 bg-no-repeat xl:top-[10px];
          content: '';
          background-image: url(@images/triangle-white-right.svg);
        }
      }
    }
  }

  .wysiwyg-circle-list ul,
  ul.as-circle-list {
    @apply my-4 ml-1;

    li {
      @apply relative my-2;
      padding-left: 1.5em;

      &:before {
        @apply absolute top-0 left-0 font-medium text-blue;
        content: '\2022';
        font-size: 2.5em;
        line-height: 0.7;
      }
    }

    .text-white & li:before {
      color: white;
    }
  }

  .wysiwyg-ft {
    a[href$='.pdf']:not(.ignore-ft) {
      @apply inline-flex align-middle font-medium no-underline;

      &:before {
        @apply mr-3 block flex-shrink-0 bg-contain bg-no-repeat;
        content: '';
        background-image: url('@images/ft-pdf.svg');
        height: 1.5em;
        width: 1.5em;
      }
    }
  }

  .ss-htmleditorfield-file.image {
    @apply my-8 w-full;
  }

  .ss-htmleditorfield-file.image.center {
    @apply max-w-2xl md:mx-auto md:max-w-[75%];
  }

  .ss-htmleditorfield-file.image.center,
  img.center,
  img.aligncenter {
    @apply my-6;
  }

  .ss-htmleditorfield-file.image.left,
  img.left,
  img.alignleft {
    @apply my-6 md:float-left md:mt-0 md:mr-6 md:max-w-[50%] xl:mr-10;
  }

  .ss-htmleditorfield-file.image.right,
  img.right,
  img.alignright {
    @apply my-6 md:float-right md:mt-0 md:ml-6 md:max-w-[50%] xl:ml-10;
  }

  .ss-htmleditorfield-file.embed.left {
    @apply md:float-left md:mb-6 md:mr-6 xl:mr-10;
  }

  .ss-htmleditorfield-file.embed.right {
    @apply md:float-right md:mb-6 md:ml-6 xl:ml-10;
  }

  .embed-container,
  .ss-htmleditorfield-file.embed {
    @apply relative my-8 !w-full;

    &:after {
      content: '';
      display: block;
      padding-top: 56.25%;
    }

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .captionImage {
    @apply my-8 lg:my-16;
    width: 100% !important;
  }

  .captionImage.left {
    @apply my-6 md:float-left md:mt-0 md:mr-6 md:max-w-[50%] xl:mr-10;

    img {
      @apply !float-none !mx-0 !max-w-none;
    }
  }

  .captionImage.right {
    @apply my-6 md:float-right md:mt-0 md:ml-6 md:max-w-[50%] xl:ml-10;

    img {
      @apply !float-none !mx-0 !max-w-none;
    }
  }

  .caption {
    @apply text-base italic text-gray;
  }

  .wysiwyg-article {
    blockquote {
      @apply my-8 text-lg italic md:text-xl lg:text-2xl;
    }
  }
}

ul.as-checklist-white,
ul.as-checklist-white-md,
ul.as-checklist-white-lg {
  @apply my-4 space-y-3 py-2 xl:space-y-4;

  & > li {
    @apply relative pl-9 text-left font-medium text-white;

    &:before {
      @apply absolute left-0 -top-0.5 h-6 w-6 bg-no-repeat lg:top-[1px];
      content: '';
      background-image: url(@images/circle-check-white.svg);
    }
  }
}

ul.as-checklist-blue,
ul.as-checklist-blue-md,
ul.as-checklist-blue-lg {
  @apply my-4 space-y-2 py-2 xl:space-y-3;

  & > li {
    @apply text-primary relative text-left;

    &:before {
      @apply absolute left-0 top-0 bg-no-repeat;
      content: '';
      background-image: url(@images/circle-check-blue.svg);
    }
  }

  ul:not([class]) li {
    @apply text-base font-normal text-black xl:text-lg;
    padding-left: 1.5em;

    &:before {
      @apply absolute left-0 -top-0.5 font-medium text-green;
      content: '\2022';
      font-size: 2.5em;
      line-height: 0.7;
    }
  }
}

ul.as-checklist-blue {
  li {
    @apply pl-9 text-lg lg:text-xl;

    &:before {
      @apply h-6 w-6 lg:top-[1px];
    }
  }
}

ul.as-checklist-blue-md,
ul.as-checklist-white-md {
  li {
    @apply pl-9 text-lg lg:text-xl xl:pl-10 xl:text-2xl;

    &:before {
      @apply h-6 w-6 xl:h-7 xl:w-7;
    }
  }
}

ul.as-checklist-blue-lg,
ul.as-checklist-white-lg {
  li {
    @apply pl-9 text-xl lg:pl-10 lg:text-2xl xl:text-3xl;

    &:before {
      @apply h-7 w-7 lg:h-8 lg:w-8 xl:top-0.5;
    }
  }
}

ul.as-checklist-green {
  @apply my-4 space-y-2 py-2 xl:space-y-3;

  & > li {
    @apply text-secondary relative pl-9 text-left text-lg lg:text-xl;

    &:before {
      @apply absolute left-0 top-0 h-6 w-6 bg-no-repeat lg:top-[1px];
      content: '';
      background-image: url(@images/circle-check-green.svg);
    }
  }
}
