@media print {
  html {
    font-size: x-small;
  }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  a:after,
  a[href]:after,
  a:visited:after {
    content: normal !important;
  }

  .container {
    max-width: none !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .site-header {
    filter: none !important;
    border: none;
    position: relative !important;
    top: auto !important;
    box-shadow: none !important;
  }

  .site-logo {
    width: 260px !important;
  }

  [class*='bg-gradient'] {
    background: none !important;
  }

  .element-section {
    break-inside: avoid;
  }

  .wysiwyg-article {
    p {
      break-inside: avoid;
    }
  }

  .wysiwyg {
    img {
      max-width: 35%;
    }
  }

  [data-modal='subscribe'],
  #page-filters {
    display: none !important;
  }

  .site-footer {
    break-inside: avoid;
  }

  [data-sr] {
    opacity: 1 !important;
    visibility: visible !important;
    transform: none !important;
  }
}
