:root {
  --container-width: 1340px;
  --container-sm-width: 1140px;
  --container-padding: 20px;
  --site-header-height: 73px;
  --site-announcement-height: 0px;
  --site-header-offset: calc(var(--site-header-height) + var(--site-announcement-height));

  @screen md {
    --container-padding: 6vw;
  }

  @screen xl {
    --container-padding: 75px;
  }
}
