.bg-gradient-gray {
  background-image: linear-gradient(to bottom, #f4f5f6, #fff);
}

.bg-gradient-gray-border {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 3px),
    linear-gradient(to bottom, rgba(238, 239, 241, 0.6) 5%, rgba(255, 255, 255, 0.6) 100%);
}

.bg-gradient-gray-sm {
  background: linear-gradient(to bottom, #ffffff 90%, #eeeff1 98%);
}

.bg-gradient-white-to-gray {
  background-image: linear-gradient(to bottom, rgb(255, 255, 255), rgb(238, 239, 241));
}

[data-hover-image] {
  img {
    @apply transition-transform duration-300;
  }

  &:hover img {
    transform: scale(1.03);
  }
}

.headline-hr {
  @apply overflow-hidden;

  span {
    @apply relative;

    &:after {
      @apply absolute bottom-[0.25em] left-full ml-[0.5em] w-screen border-b border-blue;
      content: '';
    }
  }
}

.headline-hr-lg {
  @apply overflow-hidden;

  span {
    @apply relative;

    &:after {
      @apply absolute bottom-[0.55em] left-full ml-[0.5em] w-screen border-b-4 border-blue;
      content: '';
    }
  }
}

.ai-loader {
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: 36px;
  height: 36px;

  .path {
    stroke: theme('colors.blue.DEFAULT');
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  &--light .path {
    stroke: white;
  }

  &--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.ai-scroller {
  &::-webkit-scrollbar {
    appearance: none;
    width: 7px;
    background: rgba(196, 196, 196, 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    @apply rounded bg-blue;
  }
}

@-moz-document url-prefix() {
  .ai-scroller-container.has-scrollbar {
    position: relative;

    &:before {
      content: '';
      width: 7px;
      background: rgba(196, 196, 196, 0.5);
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 2px;
      bottom: 0;
      z-index: -1;
    }
  }

  .ai-scroller {
    scrollbar-color: theme('colors.blue.DEFAULT') transparent;
  }
}

.larger-logo img {
    @apply lg:max-w-[140%];
}
