.site-menu {
  @apply fixed top-0 right-0 z-40 h-screen w-full max-w-[420px] bg-white transition-transform duration-300;
  transform: translate3d(100%, 0, 0);

  .js-site-menu-open & {
    @apply shadow;
    transform: translate3d(0, 0, 0);
  }
}

.site-menu-primary {
  & > li > span > a {
    @apply text-lg font-medium md:text-xl;
  }
}

.site-menu-primary,
.site-menu-secondary {
  & > li li {
    @apply -ml-3;
  }
}

.site-menu-screen {
  @apply pointer-events-none fixed inset-0 z-30 opacity-0 transition-opacity;

  &:before {
    @apply absolute inset-0 z-20 bg-white bg-opacity-30;
    content: '';
  }

  .js-site-menu-open & {
    @apply pointer-events-auto opacity-100;
  }
}
