@layer components {
  .btn {
    @apply inline-flex justify-center border py-3 px-5 text-center font-medium transition-colors md:px-6;
  }

  .btn-hollow-secondary {
    @apply border-green text-green hover:border-blue hover:bg-blue hover:text-white;
  }

  .btn-hollow-white {
    @apply border-white text-white hover:bg-white hover:text-blue;
  }

  .btn-fill-white {
    @apply border-white bg-white text-green hover:bg-transparent hover:text-white;
  }

  .btn-borderless-white {
    @apply border-transparent text-white hover:border-white hover:bg-white hover:text-blue;
  }

  .btn-fill-primary {
    @apply border-blue bg-blue text-white hover:border-green hover:bg-green;
  }

  .btn-fill-secondary {
    @apply border-green bg-green text-white hover:border-blue hover:bg-blue;
  }

  .btn-fill-red {
    @apply border-red bg-red text-white hover:bg-white hover:text-red;
  }

  .btn-borderless-secondary {
    @apply border-transparent text-green hover:text-blue;
  }

  .btn-icon {
    @apply inline-flex items-center justify-between;
  }

  .btn-text-primary {
    @apply font-medium text-blue transition-colors hover:text-green;
  }

  .btn-text-secondary {
    @apply font-medium text-green transition-colors hover:text-blue;
  }

  .btn-text-white {
    @apply font-medium text-white hover:underline;
  }

  .btn-box-arrow-secondary {
    @apply bg-green text-white transition-colors hover:bg-blue;
  }

  .btn-text-lg {
    @apply py-2 text-lg lg:text-xl xl:text-2xl;
  }

  .btn span {
    @apply my-auto;
  }
}

@layer utilities {
  .btn-grow {
    min-width: 224px;
  }
}
