.page-security {
  .page-container {
    @apply mt-8 md:mt-16 xl:mt-24;
  }

  .page-content {
    @apply text-center;
  }

  p.message {
    @apply mx-auto max-w-lg;
  }

  .btn-toolbar {
    @apply mt-8;

    [type='submit'] {
      @apply inline-flex cursor-pointer justify-center border-green bg-green py-3 px-5 text-center font-medium text-white transition-colors hover:border-blue hover:bg-blue md:px-6;
    }
  }
}

.pagetype-sitemappage {
  .sitemap > .sitemap-item > a {
    @apply font-bold;
  }
}
