@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'base/vars';
@import 'base/components';
@import 'base/elements';
@import 'base/pages';
@import 'base/layout';
@import 'base/typography';
@import 'base/print';
@import 'components/button';
@import 'components/mobile-bar';
@import 'components/form';
@import 'components/footer-subscribe-form';
@import 'components/page-header';
@import 'components/site-header';
@import 'components/site-menu';
@import 'components/site-widget';
@import 'components/slider';

@import 'thirdparty/iubenda';
@import 'thirdparty/silverstripe';
@import 'thirdparty/thirdparty';

@import 'base/specifics';

@import 'tailwindcss/utilities';
