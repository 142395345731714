.page-header-angled {
  .page-header-image {
    @screen lg {
      &:before {
        @apply pointer-events-none absolute -left-4 top-0 h-full w-full bg-gray-lightest;
        transform: scale(0.9);
        transform-origin: left;
        content: '';
      }

      &:after {
        @apply absolute bottom-0 left-0 h-14 w-full bg-white;
        content: '';
      }

      &:before,
      .page-header-bg-image {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 200px 100%);
      }
    }
  }

  &.has-video-bar {
    @apply lg:mb-24 xl:mb-28;
  }
}

.page-header-as-blue {
  @apply bg-blue text-white;

  .text-primary,
  .text-secondary {
    @apply text-white;
  }
}

.page-header-lead-in [class*='as-checklist'] {
  @apply my-6;
}
