#iubenda-cs-banner {
  font-size: 14px;
  line-height: 1.4;
  position: fixed;
  z-index: 99999998;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  transition: opacity 0.4s ease, visibility 0.4s ease;
}

#iubenda-cs-banner .iubenda-cs-container {
  position: relative;
  z-index: 2;
  margin-top: auto;
  width: 100%;
}

#iubenda-cs-banner .iubenda-cs-content {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background: white;
  padding: 24px;
  box-shadow: 0 8px 48px rgba(0, 0, 0, 0.15);
}

#iubenda-cs-banner.iubenda-cs-visible {
  opacity: 1;
  visibility: visible;
}

#iubenda-cs-banner.iubenda-cs-visible > * {
  pointer-events: auto;
}

#iubenda-cs-banner.iubenda-cs-slidein .iubenda-cs-container {
  transition: transform 0.4s ease;
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-top .iubenda-cs-container {
  transform: translateY(-48px);
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-bottom .iubenda-cs-container {
  transform: translateY(48px);
}

#iubenda-cs-banner.iubenda-cs-slidein.iubenda-cs-visible .iubenda-cs-container {
  transform: translateY(0);
}

#iubenda-cs-banner.iubenda-cs-slidein .iubenda-cs-container {
  transition: transform 0.4s ease;
}

#iubenda-cs-banner .iubenda-cs-content a {
  color: #017971;
  font-weight: 500;
  text-decoration: underline;
  transition: color 0.2s ease-in-out;
}

#iubenda-cs-banner .iubenda-cs-content a:hover {
  color: #005288;
}

#iubenda-cs-banner .iubenda-cs-opt-group {
  margin-top: 16px;
}

#iubenda-cs-banner .iubenda-cs-opt-group button {
  appearance: none;
  padding: 10px 32px;
  cursor: pointer;
  font-weight: 700;
  font-size: 100%;
  text-align: center;
  transition: background 0.2s ease-in-out;
  display: block;
  width: 100%;
  color: white;
  background: #017971;
}

#iubenda-cs-banner .iubenda-cs-opt-group button:hover {
  background: #005288;
}

.iubenda-uspr-btn,
#iubenda-cs-banner .iubenda-cs-opt-group-custom,
#iubenda-cs-banner .iubenda-cs-opt-group-consent .iubenda-cs-reject-btn {
  display: none;
}

#iubenda-cs-paragraph {
  padding: 16px 36px 0 0;
}

#iubenda-cs-paragraph a {
  font-weight: bold;
  text-decoration: underline;
}

#iubenda-cs-banner .iubenda-cs-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  line-height: 1;
  z-index: 2;
}

@media (min-width: 768px) {
  #iubenda-cs-banner .iubenda-cs-content {
    padding: 16px 24px;
  }

  #iubenda-cs-banner .iubenda-cs-opt-group {
    margin: 0 0 0 36px;
  }

  #iubenda-cs-banner .iubenda-cs-rationale {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #iubenda-cs-paragraph {
    padding: 0 24px 0 0;
  }

  #iubenda-cs-banner .iubenda-cs-close-btn {
    padding-top: 12px;
  }
}

#iubenda_policy {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  span,
  a,
  ul,
  ol,
  li {
    @apply font-sans;
  }

  h2 {
    text-transform: capitalize;
    font-variant: none !important;
  }

  h1,
  h2 {
    @apply mb-2 text-2xl font-semibold;
  }

  h3,
  h4,
  h5,
  h6 {
    @apply text-lg font-semibold;
  }

  h2 + h3 {
    @apply mt-6;
  }
}

.page-cookie-policy #iubenda_policy h2 {
  text-transform: none;
}
