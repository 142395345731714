.footer-subscribe-message {
  span {
    @apply lg:ml-auto lg:block lg:w-52;

    & + br {
      @apply lg:hidden;
    }
  }
}

.footer-subscribe-form {
  .field.text {
    @apply flex-1;

    .form-field > label,
    .form-field-label {
      @apply sr-only;
    }
  }

  input {
    @apply block w-full appearance-none bg-white px-5 py-4 text-base text-black placeholder-gray;
  }

  [type='submit'] {
    @apply flex items-center md:h-full md:min-w-[140px];
  }

  .field.checkboxset {
    @apply my-4 md:my-6 lg:m-0;

    & > .form-field {
      & > label {
        @apply font-bold;
      }
    }
  }

  ul.checkboxset {
    @apply mt-4 grid flex-shrink-0 grid-cols-2 md:flex md:space-x-5 lg:grid lg:grid-cols-3 lg:gap-y-1 lg:gap-x-2 lg:space-x-0 2xl:flex 2xl:justify-between 2xl:gap-0 2xl:space-x-2;
  }

  .field.checkboxset li,
  .field.checkbox {
    label {
      @apply relative inline-flex cursor-pointer items-baseline text-sm font-bold xl:text-base;

      &:before {
        @apply relative top-[0.35em] mr-2 h-5 w-5 flex-shrink-0 border border-gray-light bg-white xl:top-[0.25em] xl:mr-3;
        content: '';
      }

      &:hover {
        @apply opacity-80;
      }
    }

    input[type='checkbox'] {
      @apply invisible absolute w-0;

      &:checked ~ label {
        &:before {
          @apply border-green bg-center bg-no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.581.429A1.454 1.454 0 0 0 10.995.11a1.44 1.44 0 0 0-.471.318L4.438 6.486l-1.95-1.964A1.456 1.456 0 1 0 .418 6.571l2.978 3a1.454 1.454 0 0 0 2.057.004l7.122-7.084a1.467 1.467 0 0 0 .01-2.055l-.003-.007Z' fill='%23017971'/%3E%3C/svg%3E");
          background-size: 60%;
        }
      }
    }
  }
}
