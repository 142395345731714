/* Page-specific styling */

.page-government-non-profits {
  .page-header-lead-in {
    @apply flex items-center md:w-4/5 lg:w-full;

    p:last-child {
      @apply m-0 w-36 flex-shrink-0 md:w-48 lg:-mr-4 xl:-mt-4 xl:w-52;
    }
  }

  .element-content {
    .element-container {
      @apply m-0 pb-24 md:pb-32 xl:pb-44;
    }

    & + .element-logos {
      @apply relative -mt-16 xl:-mt-24;

      .element-container {
        @apply mt-0;
      }
    }
  }
}

.pagetype-whowevehelpedpage {
  .page-header ul.as-checklist-blue {
    @apply mx-auto max-w-[560px];
  }
}
